import { LocationRawData } from "types/location";

export class Location {
  locationId: string;
  country: string;
  city: string;
  displayName: string;

  constructor(data: LocationRawData) {
    this.locationId = data.location_id;
    this.country = data.country;
    this.city = data.city;
    this.displayName = data.display_name;
  }
}
