import { ResponseStatus } from "types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";
import { getPaxerProfile, getPaxerList, getFilteredPaxerList } from "services/ProfileService";
import { User } from "models";
import { UsersFilters } from "types/users";

export interface ProfileState {
  paxerStatus: ResponseStatus;
  paxer: any;
  paxerListStatus: ResponseStatus;
  paxerList: any;
}

const initialState: ProfileState = {
  paxerStatus: ResponseStatus.LOADING,
  paxer: null,
  paxerListStatus: ResponseStatus.LOADING,
  paxerList: [],
};

export const getPaxerProfileAsync = createAsyncThunk("auth/getPaxerProfileAsync", async (id: string) => {
  const profile = await getPaxerProfile(id);

  return new User(profile);
});

export const getPaxerListAsync = createAsyncThunk("auth/getPaxerListAsync", async () => {
  const resp = await getPaxerList();

  return resp.profiles.map((profile) => new User(profile));
});

export const getFilteredPaxerListAsync = createAsyncThunk("auth/getFilteredPaxerListAsync", async (filters: UsersFilters) => {
  const resp = await getFilteredPaxerList(filters);

  return resp.profiles.map((profile) => new User(profile));
});

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearPaxerData: (state) => {
      state.paxer = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaxerProfileAsync.pending, (state) => {
        state.paxerStatus = ResponseStatus.LOADING;
      })
      .addCase(getPaxerProfileAsync.fulfilled, (state, action) => {
        state.paxerStatus = ResponseStatus.IDLE;
        state.paxer = action.payload;
      })
      .addCase(getPaxerProfileAsync.rejected, (state) => {
        state.paxerStatus = ResponseStatus.FAILED;
      });

    builder
      .addCase(getPaxerListAsync.pending, (state) => {
        state.paxerListStatus = ResponseStatus.LOADING;
      })
      .addCase(getPaxerListAsync.fulfilled, (state, action) => {
        state.paxerListStatus = ResponseStatus.IDLE;
        state.paxerList = action.payload;
      })
      .addCase(getPaxerListAsync.rejected, (state) => {
        state.paxerListStatus = ResponseStatus.FAILED;
      });

    builder
      .addCase(getFilteredPaxerListAsync.pending, (state) => {
        state.paxerListStatus = ResponseStatus.LOADING;
      })
      .addCase(getFilteredPaxerListAsync.fulfilled, (state, action) => {
        state.paxerListStatus = ResponseStatus.IDLE;
        state.paxerList = action.payload;
      })
      .addCase(getFilteredPaxerListAsync.rejected, (state) => {
        state.paxerListStatus = ResponseStatus.FAILED;
      });
  },
});

export const profileSelectors = {
  paxerStatus: (state: RootState) => state.profile.paxerStatus,
  paxer: (state: RootState) => state.profile.paxer,
  paxerListStatus: (state: RootState) => state.profile.paxerListStatus,
  paxerList: (state: RootState) => state.profile.paxerList,
};

export const { clearPaxerData } = profileSlice.actions;

export default profileSlice.reducer;
