import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchLiveStreams,
  fetchLiveStreamsPrivate,
  fetchStreamerStreams,
  fetchStreamInfo,
  fetchStreamInfoPrivate,
  generateStreamToken,
  fetchScheduledStreams,
  rateStream,
  RateStreamRequestData,
  fetchScheduledStreamsPrivate,
  fetchStreamerStreamsPrivate,
  purchaseStream,
  fetchSubscriptionStreamsLive,
  fetchSubscriptionStreamsScheduled,
} from "services/StreamService";
import { fetchChatToken, APIAgoraChatTokenData } from "services/ChatApiService";
import { ResponseStatus } from "types";
import { OwnerPosition, StreamTypes } from "types/streams";
import { Stream } from "models";
import type { RootState } from "store";
import { CursorPagination, Pagination } from "types/request";
import { StreamsFilters } from "types/streams";
import { getStreamType } from "utils/Stream";

export interface VideoStreamState {
  rateStreamStatus: ResponseStatus;
  liveStreamsStatus: ResponseStatus;
  liveStreams: Stream[] | null;
  scheduledStreamsStatus: ResponseStatus;
  scheduledStreams: Stream[] | null;
  subscriptionLiveStreamsStatus: ResponseStatus;
  subscriptionLiveStreams: Stream[] | null;
  subscriptionScheduledStreamsStatus: ResponseStatus;
  subscriptionScheduledStreams: Stream[] | null;
  streamerStreamsStatus: ResponseStatus;
  streamerStreams: Stream[] | null;
  streamInformationStatus: ResponseStatus;
  streamInformation: Stream | null;
  streamTokenStatus: ResponseStatus;
  streamToken: string | null;
  rtmToken: string | null;
  chatToken: APIAgoraChatTokenData | null;
  purchaseStreamStatus: ResponseStatus;
  isFrameOpened: boolean;
  streamerLocation: OwnerPosition | null;
}

const initialState: VideoStreamState = {
  rateStreamStatus: ResponseStatus.IDLE,
  liveStreamsStatus: ResponseStatus.LOADING,
  liveStreams: null, // List of the live streams
  scheduledStreamsStatus: ResponseStatus.LOADING,
  scheduledStreams: null, // List of the scheduled streams
  subscriptionLiveStreamsStatus: ResponseStatus.LOADING,
  subscriptionLiveStreams: null,
  subscriptionScheduledStreamsStatus: ResponseStatus.LOADING,
  subscriptionScheduledStreams: null,
  streamerStreamsStatus: ResponseStatus.LOADING,
  streamerStreams: null, // List of the user's streams (live, schedule)
  streamInformationStatus: ResponseStatus.LOADING,
  streamInformation: null, // Streaming information
  streamTokenStatus: ResponseStatus.LOADING,
  streamToken: null, // Stream token
  rtmToken: null,
  chatToken: null,
  purchaseStreamStatus: ResponseStatus.IDLE,
  isFrameOpened: true,
  streamerLocation: null
};

export const fetchLiveStreamsAsync = createAsyncThunk(
  "videoStream/fetchLiveStreamsAsync",
  async (params: Pagination & StreamsFilters): Promise<Stream[]> => {
    const response = await fetchLiveStreams(params);

    if (response) {
      return response.streams?.map((stream) => new Stream(stream, StreamTypes.Live));
    }
  },
);

export const fetchLiveStreamsPrivateAsync = createAsyncThunk(
  "videoStream/fetchLiveStreamsPrivateAsync",
  async (params: Pagination & StreamsFilters): Promise<Stream[]> => {
    const response = await fetchLiveStreamsPrivate(params);

    if (response) {
      return response.streams?.map((stream) => new Stream(stream, StreamTypes.Live));
    }
  },
);

export const fetchScheduledStreamsAsync = createAsyncThunk(
  "videoStream/fetchScheduledStreamsAsync",
  async (params: Pagination & StreamsFilters) => {
    const response = await fetchScheduledStreams(params);

    if (response) {
      return response.streams?.map((stream) => new Stream(stream, StreamTypes.Schedule));
    }
  },
);

export const fetchScheduledStreamsPrivateAsync = createAsyncThunk(
  "videoStream/fetchScheduledStreamsPrivateAsync",
  async (params: Pagination & StreamsFilters) => {
    const response = await fetchScheduledStreamsPrivate(params);

    if (response) {
      return response.streams?.map((stream) => new Stream(stream, StreamTypes.Schedule));
    }
  },
);

export const fetchSubscriptionLiveStreamsAsync = createAsyncThunk(
  "auth/getSubscriptionStreamsLive",
  async (params: CursorPagination): Promise<{ next: string; streams: Stream[] }> => {
    const response = await fetchSubscriptionStreamsLive(params);

    if (response) {
      return { ...response, streams: response.streams?.map((stream) => new Stream(stream, StreamTypes.Live)) };
    }
  },
);

export const fetchSubscriptionScheduledStreamsAsync = createAsyncThunk(
  "auth/getSubscriptionStreamsScheduled",
  async (params: CursorPagination): Promise<{ next: string; streams: Stream[] }> => {
    const response = await fetchSubscriptionStreamsScheduled(params);

    if (response) {
      return { ...response, streams: response.streams?.map((stream) => new Stream(stream, StreamTypes.Schedule)) };
    }
  },
);

export const fetchUserStreamsAsync = createAsyncThunk("videoStream/fetchUserStreamsAsync", async (streamerId: string) => {
  const response = await fetchStreamerStreams(streamerId);

  if (response) {
    const { live, scheduled, finished } = response;

    return [
      ...live.streams.map((stream) => {
        try {
          new Stream(stream, StreamTypes.Live);
        } catch (e) {
          console.log(e);
        }

        return new Stream(stream, StreamTypes.Live);
      }),
      ...scheduled.streams.map((stream) => new Stream(stream, StreamTypes.Schedule)),
      ...finished.streams.map((stream) => new Stream(stream, StreamTypes.Finished)),
    ];
  }
});

export const fetchUserStreamsPrivateAsync = createAsyncThunk("videoStream/fetchUserStreamsPrivateAsync", async (streamerId: string) => {
  const response = await fetchStreamerStreamsPrivate(streamerId);

  if (response) {
    const { live, scheduled, finished } = response;

    return [
      ...live.streams.map((stream) => new Stream(stream, StreamTypes.Live)),
      ...scheduled.streams.map((stream) => new Stream(stream, StreamTypes.Schedule)),
      ...finished.streams.map((stream) => new Stream(stream, StreamTypes.Finished)),
    ];
  }
});

export const fetchStreamInformationAsync = createAsyncThunk("videoStream/fetchStreamInformationAsync", async (streamId: string) => {
  const response = await fetchStreamInfo(streamId);

  if (response) {
    const streamType = getStreamType(response);
    return new Stream(response, streamType);
  }
});

export const fetchStreamInformationPrivateAsync = createAsyncThunk(
  "videoStream/fetchStreamInformationPrivateAsync",
  async (streamId: string) => {
    const response = await fetchStreamInfoPrivate(streamId);

    if (response) {
      const streamType = getStreamType(response);
      return new Stream(response, streamType);
    }
  },
);

export const generateStreamTokenAsync = createAsyncThunk("videoStream/generateStreamToken", async (id: string) => {
  return await generateStreamToken({ id });
});

export const rateStreamAsync = createAsyncThunk("videoStream/rateStreamAsync", async (data: RateStreamRequestData) => {
  const response = await rateStream(data);

  if (response) {
    return new Stream(response);
  }
});

export const fetchChatTokenAsync = createAsyncThunk("videoStream/fetchChatTokenAsync", async () => {
  const response = await fetchChatToken();

  if (response) {
    return response;
  }
});

export const purchaseStreamAsync = createAsyncThunk("videoStream/purchaseStreamTokenAsync", async (streamId: string) => {
  return await purchaseStream(streamId);
});

export const videoStreamSlice = createSlice({
  name: "videoStream",
  initialState,
  reducers: {
    resetRateStreamStatus: (state) => {
      state.rateStreamStatus = ResponseStatus.IDLE;
    },
    resetStreamToken: (state) => {
      state.streamTokenStatus = ResponseStatus.LOADING;
      state.streamToken = null;
    },
    setRecordStreamInformation: (state, action) => {
      state.streamInformationStatus = ResponseStatus.IDLE;
      state.streamInformation = action.payload;
    },
    setStreamInformation: (state, action) => {
      state.streamInformationStatus = ResponseStatus.IDLE;
      state.streamInformation = action.payload;
    },
    resetStreamInfo: (state) => {
      state.streamInformationStatus = ResponseStatus.LOADING;
      state.streamInformation = null;
    },
    resetStreamPurchaseStatus: (state) => {
      state.purchaseStreamStatus = ResponseStatus.IDLE;
    },
    setIsFrameOpened: (state, action) => {
      state.isFrameOpened = action.payload;
    },
    setStreamerLocation: (state, action) => {
      const { stream_id, lat, lon } = action.payload;

      if (stream_id === state.streamInformation.id) {
        state.streamerLocation = { lat, lng: lon };
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchUserStreamsAsync.pending, (state) => {
        state.streamerStreamsStatus = ResponseStatus.LOADING;
      })
      .addCase(fetchUserStreamsAsync.fulfilled, (state, action) => {
        state.streamerStreamsStatus = ResponseStatus.IDLE;
        state.streamerStreams = action.payload;
      })
      .addCase(fetchUserStreamsAsync.rejected, (state) => {
        state.streamerStreamsStatus = ResponseStatus.FAILED;
        state.streamerStreams = [];
      })

      .addCase(fetchUserStreamsPrivateAsync.pending, (state) => {
        state.streamerStreamsStatus = ResponseStatus.LOADING;
      })
      .addCase(fetchUserStreamsPrivateAsync.fulfilled, (state, action) => {
        state.streamerStreamsStatus = ResponseStatus.IDLE;
        state.streamerStreams = action.payload;
      })
      .addCase(fetchUserStreamsPrivateAsync.rejected, (state) => {
        state.streamerStreamsStatus = ResponseStatus.FAILED;
        state.streamerStreams = [];
      })

      .addCase(fetchLiveStreamsAsync.pending, (state) => {
        state.liveStreamsStatus = ResponseStatus.LOADING;
      })
      .addCase(fetchLiveStreamsAsync.fulfilled, (state, action) => {
        state.liveStreamsStatus = ResponseStatus.IDLE;
        state.liveStreams = action.payload;
      })
      .addCase(fetchLiveStreamsAsync.rejected, (state) => {
        state.liveStreamsStatus = ResponseStatus.FAILED;
      })

      .addCase(fetchLiveStreamsPrivateAsync.pending, (state) => {
        state.liveStreamsStatus = ResponseStatus.LOADING;
      })
      .addCase(fetchLiveStreamsPrivateAsync.fulfilled, (state, action) => {
        state.liveStreamsStatus = ResponseStatus.IDLE;
        state.liveStreams = action.payload;
      })
      .addCase(fetchLiveStreamsPrivateAsync.rejected, (state) => {
        state.liveStreamsStatus = ResponseStatus.FAILED;
      })

      .addCase(fetchScheduledStreamsAsync.pending, (state) => {
        state.scheduledStreamsStatus = ResponseStatus.LOADING;
      })
      .addCase(fetchScheduledStreamsAsync.fulfilled, (state, action) => {
        state.scheduledStreamsStatus = ResponseStatus.IDLE;
        state.scheduledStreams = action.payload;
      })
      .addCase(fetchScheduledStreamsAsync.rejected, (state) => {
        state.scheduledStreamsStatus = ResponseStatus.FAILED;
      })

      .addCase(fetchScheduledStreamsPrivateAsync.pending, (state) => {
        state.scheduledStreamsStatus = ResponseStatus.LOADING;
      })
      .addCase(fetchScheduledStreamsPrivateAsync.fulfilled, (state, action) => {
        state.scheduledStreamsStatus = ResponseStatus.IDLE;
        state.scheduledStreams = action.payload;
      })
      .addCase(fetchScheduledStreamsPrivateAsync.rejected, (state) => {
        state.scheduledStreamsStatus = ResponseStatus.FAILED;
      })

      .addCase(fetchSubscriptionLiveStreamsAsync.pending, (state) => {
        state.subscriptionLiveStreamsStatus = ResponseStatus.LOADING;
      })
      .addCase(fetchSubscriptionLiveStreamsAsync.fulfilled, (state, action) => {
        state.subscriptionLiveStreamsStatus = ResponseStatus.IDLE;
        state.subscriptionLiveStreams = action.payload?.streams || [];
      })
      .addCase(fetchSubscriptionLiveStreamsAsync.rejected, (state) => {
        state.subscriptionLiveStreamsStatus = ResponseStatus.FAILED;
      })

      .addCase(fetchSubscriptionScheduledStreamsAsync.pending, (state) => {
        state.subscriptionScheduledStreamsStatus = ResponseStatus.LOADING;
      })
      .addCase(fetchSubscriptionScheduledStreamsAsync.fulfilled, (state, action) => {
        state.subscriptionScheduledStreamsStatus = ResponseStatus.IDLE;
        state.subscriptionScheduledStreams = action.payload?.streams || [];
      })
      .addCase(fetchSubscriptionScheduledStreamsAsync.rejected, (state) => {
        state.subscriptionScheduledStreamsStatus = ResponseStatus.FAILED;
      })

      .addCase(fetchStreamInformationAsync.pending, (state) => {
        state.streamInformationStatus = ResponseStatus.LOADING;
      })
      .addCase(fetchStreamInformationAsync.fulfilled, (state, action) => {
        state.streamInformationStatus = ResponseStatus.IDLE;
        state.streamInformation = action.payload;
      })
      .addCase(fetchStreamInformationAsync.rejected, (state) => {
        state.streamInformationStatus = ResponseStatus.FAILED;
      })

      .addCase(fetchStreamInformationPrivateAsync.pending, (state) => {
        state.streamInformationStatus = ResponseStatus.LOADING;
      })
      .addCase(fetchStreamInformationPrivateAsync.fulfilled, (state, action) => {
        state.streamInformationStatus = ResponseStatus.IDLE;
        state.streamInformation = action.payload;

        if (action.payload.ownerPosition) {
          const { lat, lng } = action.payload.ownerPosition;

          state.streamerLocation = { lat, lng };
        }
      })
      .addCase(fetchStreamInformationPrivateAsync.rejected, (state) => {
        state.streamInformationStatus = ResponseStatus.FAILED;
      })

      .addCase(generateStreamTokenAsync.pending, (state) => {
        state.streamTokenStatus = ResponseStatus.LOADING;
      })
      .addCase(generateStreamTokenAsync.fulfilled, (state, action) => {
        state.streamTokenStatus = ResponseStatus.IDLE;
        state.streamToken = action.payload?.rtc_token;
        state.rtmToken = action.payload?.rtm_token;
      })
      .addCase(generateStreamTokenAsync.rejected, (state) => {
        state.streamTokenStatus = ResponseStatus.FAILED;
      })

      .addCase(rateStreamAsync.pending, (state) => {
        state.rateStreamStatus = ResponseStatus.LOADING;
      })
      .addCase(rateStreamAsync.fulfilled, (state, action) => {
        state.rateStreamStatus = ResponseStatus.SUCCESS;
        state.streamInformation = action.payload;
      })
      .addCase(rateStreamAsync.rejected, (state) => {
        state.rateStreamStatus = ResponseStatus.FAILED;
      })

      .addCase(fetchChatTokenAsync.pending, () => {})
      .addCase(fetchChatTokenAsync.fulfilled, (state, action) => {
        state.chatToken = action.payload;
      })
      .addCase(fetchChatTokenAsync.rejected, (state) => {
        state.chatToken = null;
      })

      .addCase(purchaseStreamAsync.pending, (state) => {
        state.purchaseStreamStatus = ResponseStatus.LOADING;
      })
      .addCase(purchaseStreamAsync.fulfilled, (state) => {
        state.purchaseStreamStatus = ResponseStatus.SUCCESS;
      })
      .addCase(purchaseStreamAsync.rejected, (state) => {
        state.purchaseStreamStatus = ResponseStatus.FAILED;
      });
  },
});

export const videoStreamSelectors = {
  rateStreamStatus: (state: RootState) => state.videoStream.rateStreamStatus,
  liveStreams: (state: RootState) => state.videoStream.liveStreams || [],
  scheduledStreams: (state: RootState) => state.videoStream.scheduledStreams || [],
  subscriptionLiveStreams: (state: RootState) => state.videoStream.subscriptionLiveStreams || [],
  subscriptionScheduledStreams: (state: RootState) => state.videoStream.subscriptionScheduledStreams || [],
  streamerStreams: (state: RootState) => state.videoStream.streamerStreams,
  streamInformation: (state: RootState) => state.videoStream.streamInformation,
  streamerLocation: (state: RootState) => state.videoStream.streamerLocation,
  streamToken: (state: RootState) => state.videoStream.streamToken,
  rtmToken: (state: RootState) => state.videoStream.rtmToken,
  // statuses
  liveStreamsStatus: (state: RootState) => state.videoStream.liveStreamsStatus,
  scheduledStreamsStatus: (state: RootState) => state.videoStream.scheduledStreamsStatus,
  subscriptionLiveStreamsStatus: (state: RootState) => state.videoStream.subscriptionLiveStreamsStatus,
  subscriptionScheduledStreamsStatus: (state: RootState) => state.videoStream.subscriptionScheduledStreamsStatus,
  streamerStreamsStatus: (state: RootState) => state.videoStream.streamerStreamsStatus,
  streamInformationStatus: (state: RootState) => state.videoStream.streamInformationStatus,
  streamTokenStatus: (state: RootState) => state.videoStream.streamTokenStatus,
  purchaseStreamStatus: (state: RootState) => state.videoStream.purchaseStreamStatus,
  // other
  isLoadingStreamerStreams: (state: RootState) => state.videoStream.streamerStreamsStatus === ResponseStatus.LOADING,
  isLoadingLiveStreams: (state: RootState) => state.videoStream.liveStreamsStatus === ResponseStatus.LOADING,
  // chat
  chatToken: (state: RootState) => state.videoStream.chatToken,
  isFrameOpened: (state: RootState) => state.videoStream.isFrameOpened,
};

export const {
  setRecordStreamInformation,
  setStreamInformation,
  resetStreamInfo,
  resetRateStreamStatus,
  resetStreamPurchaseStatus,
  setIsFrameOpened,
  resetStreamToken,
  setStreamerLocation
} = videoStreamSlice.actions;

export default videoStreamSlice.reducer;
