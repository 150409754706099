import Client from "services/Client";
import { User } from "models";
import { RequestMethod } from "client/BaseHttpClient";
import { PublicProfileRawData } from "types/profile";
import { Pagination } from "types/request";
import { UsersFilters } from "types/users";
import { convertFiltersToRaw } from "utils/Filters";

interface SubscriptionResponse {
  profiles: Array<PublicProfileRawData>;
}

export const subscribe = (username: User["username"]): Promise<SubscriptionResponse> =>
  Client.doRequest<SubscriptionResponse>(`/api/v1/subscriptions/subscribe`, {
    method: RequestMethod.POST,
    data: { username },
  });

export const unsubscribe = (username: User["username"]): Promise<SubscriptionResponse> =>
  Client.doRequest(`/api/v1/subscriptions/unsubscribe`, {
    method: RequestMethod.POST,
    data: { username },
  });

export const getSubscriptions = (filters: Pagination & UsersFilters = {}): Promise<SubscriptionResponse> =>
  Client.doRequest(`/api/v1/subscriptions/me`, {
    method: RequestMethod.POST,
    data: convertFiltersToRaw(filters)
  });
