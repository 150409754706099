import { action, makeObservable, observable } from "mobx";
import { Location } from "react-router-dom";

class LocationHistory {
  @observable public history: Location[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  public pushLocationToHistory = (location: Location) => {
    this.history.push(location);
  };
}

export default new LocationHistory();
