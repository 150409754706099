import { Order, OrderStatus } from "types/orders";
import { getDifferenceBetweenDates, getUserFriendlyDate } from "utils/DateUtils";

const getPaymentNote = (order: Order) => {
  let payDeadline: { date: string; time: string };
  const createdAt = Date.parse(order.created_at);
  const payBefore = Date.parse(order.pay_before);
  const startAt = Date.parse(order.stream_start_time);

  if (getDifferenceBetweenDates(startAt, createdAt).days >= 1) {
    payDeadline = getUserFriendlyDate(payBefore);
  } else {
    payDeadline = getUserFriendlyDate(startAt - 300000);
  }

  return `The payment should be made before ${payDeadline.date} ${payDeadline.time}`;
};

export const ORDER_STATUS_MAP: Record<OrderStatus, string> = {
  [OrderStatus.CREATED]: "Created",
  [OrderStatus.CONFIRMED]: "Awaiting for payment",
  [OrderStatus.CHANGED]: "Changed",
  [OrderStatus.ACCEPTED]: "Awaiting for payment",
  [OrderStatus.PAID_FOR]: "Paid",
  [OrderStatus.STARTED]: "Paid",
  [OrderStatus.COMPLETED]: "Completed",
  [OrderStatus.PAID_OUT]: "Paid out",
  [OrderStatus.CANCELED]: "Canceled",
  [OrderStatus.CONFIRMATION_EXPIRED]: "Canceled",
  [OrderStatus.START_EXPIRED]: "Canceled",
  [OrderStatus.PAYMENT_EXPIRED]: "Canceled",
};

export const ORDER_STATUS_NOTES_MAP: Record<OrderStatus, (order: Order) => string> = {
  [OrderStatus.CREATED]: () => "Waiting 48 hrs for streamer's confirmation",
  [OrderStatus.CONFIRMED]: getPaymentNote,
  [OrderStatus.CHANGED]: () => "Waiting for streamer's confirmation",
  [OrderStatus.ACCEPTED]: getPaymentNote,
  [OrderStatus.PAID_FOR]: () => "",
  [OrderStatus.STARTED]: () => "",
  [OrderStatus.COMPLETED]: () => "",
  [OrderStatus.PAID_OUT]: () => "",
  [OrderStatus.CANCELED]: () => "",
  [OrderStatus.CONFIRMATION_EXPIRED]: () => "",
  [OrderStatus.START_EXPIRED]: () => "",
  [OrderStatus.PAYMENT_EXPIRED]: () => "",
};

export const MAX_ACTIVE_ORDERS_COUNT = 10;

export const MIN_STREAM_DURATION_IN_MINUTES = 15;
export const MIN_TIME_BEFORE_ASAP_ORDER_IN_MINUTES = 10;
export const MIN_TIME_BEFORE_SCHEDULED_ORDER_IN_MINUTES = 30;
export const FREE_CANCELLATION_TIME = 60000;
