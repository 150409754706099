import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import organizationReducer from "slices/organizationsSlice";
import authReducer from "./slices/authSlice";
import profileReducer from "./slices/profileSlice";
import videoStreamReducer from "./slices/videoStreamSlice";
import dictionaryListReducer from "./slices/dictionaryListSlice";
import subscriptionReducer from "./slices/subscriptionSlice";
import userReducer from "./slices/userSlice";
import usersReducer from "./slices/usersSlice";
import orderReducer from "./slices/orderSlice";
import locationReducer from "./slices/locationSlice";
import modalReducer from "./slices/modalSlice";
import walletReducer from "./slices/walletSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    videoStream: videoStreamReducer,
    dictionaryList: dictionaryListReducer,
    subscription: subscriptionReducer,
    user: userReducer,
    users: usersReducer,
    order: orderReducer,
    location: locationReducer,
    modal: modalReducer,
    wallet: walletReducer,
    organization: organizationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// удалить после переписывания ангоры
export async function dispatchFromService(action) {
  await store.dispatch(action);
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
