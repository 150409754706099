import { StreamRawData, StreamTypes } from "types/streams";
import { PublicProfileRawData } from "types/profile";
import { PublicProfile } from "./PublicProfile";
import { Stream } from "./Stream";

export interface OrganizationRawData {
  username: string;
  avatar_url?: string;
  bio?: string;
  members?: PublicProfileRawData[];
  live?: StreamRawData[];
  schedule?: StreamRawData[];
}

export class Organization {
  orgsName?: string;
  avatarURL?: string;
  aboutOrg?: string;
  membersList?: PublicProfile[];
  orgsLiveStreamList?: Stream[];
  orgsScheduledStreamList?: Stream[];

  constructor(data: OrganizationRawData) {
    this.orgsName = data.username;
    this.avatarURL = data.avatar_url;
    this.aboutOrg = data.bio;
    this.membersList = data.members.map((member) => new PublicProfile(member));
    this.orgsLiveStreamList = data.live?.map((liveStream) => new Stream(liveStream));
    this.orgsScheduledStreamList = data.schedule?.map((scheduledStream) => new Stream(scheduledStream, StreamTypes.Schedule));
  }
}
