export const breakpointValues = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1200,
  xl: 1440,
  xxl: 1920,
};

export const breakpoints = {
  xs: `(min-width: ${breakpointValues.xs}px)`,
  sm: `(min-width: ${breakpointValues.sm}px)`,
  md: `(min-width: ${breakpointValues.md}px)`,
  lg: `(min-width: ${breakpointValues.lg}px)`,
  xl: `(min-width: ${breakpointValues.xl}px)`,
  xxl: `(min-width: ${breakpointValues.xxl}px)`,
};
