export enum ResponseStatus {
  IDLE = "idle",
  LOADING = "loading",
  FAILED = "failed",
  SUCCESS = "success",
}

export enum Currency {
  Euro = "€",
  Dollar = "$",
  Franc = "£",
}

export enum ThemeName {
  Light = "light",
  Dark = "dark",
}

export enum OrderStatuses {
  Processing = "processing",
  Accepted = "accepted",
  Rejected = "rejected",
  Canceled = "canceled",
  Expired = "expired",
}
