import { useEffect, useState } from "react";
import { clearAuthData } from "slices/authSlice";
import { clearCurrentUser, userSelectors } from "slices/userSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import AuthUtilsService from "services/AuthUtilsService";

interface AuthResult {
  isAuthenticated: boolean;
  isAppReadyToUse: boolean;
  logout: () => void;
}

export const useAuth = (): AuthResult => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAppReadyToUse, setIsAppReadyToUse] = useState(false);
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(userSelectors.currentUser);

  useEffect(() => {
    setIsAuthenticated(!!currentUser);
  }, [currentUser]);

  useEffect(() => {
    setIsAppReadyToUse(!!currentUser || !AuthUtilsService.data.accessToken);
  }, [currentUser, AuthUtilsService.data.accessToken]);

  const logout = () => {
    dispatch(clearAuthData());
    dispatch(clearCurrentUser());
  };

  return {
    logout,
    isAuthenticated,
    isAppReadyToUse
  };
};
