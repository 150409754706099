import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import Root from "./components/Root";
import { store } from "./store";
import GlobalStyle from "./styles.global";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <GlobalStyle />
        <Root />
      </BrowserRouter>
    </HelmetProvider>
  </Provider>
);
