import { PublicProfileRawData } from "./profile";
import { CommissionFunds } from "./transaction";

export enum StreamTypes {
  Schedule = "schedule",
  Live = "live",
  Private = "private",
  Finished = "finished",
}

export enum StreamCategories {
  LifeStyle = "life_style",
  Sport = "sport",
}

export enum StreamEndReasons {
  NobodyCome = "no-one-on-stream",
  NoPaxer = "no-paxer-on-stream",
  NoBeholder = "no-beholder-on-stream",
  InconsistentOrder = "inconsistent-order",
  FinishedOrder = "finished-order",
}

export interface StreamsFilters {
  categories?: string[];
}

export interface StreamScheduleRawData {
  stream_id: string;
  start_unix_time: number;
  duration_sec: number;
  max_visitors: number;
  order_id: string;
}

export interface StreamUserRawData {
  id: string;
  title: string;
  desc: string;
  category: StreamCategories;
  private: boolean;
  purchased: boolean;
  price: number;
  price_funds: CommissionFunds;
  given_stars?: number;
  preview_url?: string;
}

export interface StreamOnlineRawData {
  stream_id: string;
  active_viewers: number;
  total_viewers: number;
  publishing_time: number;
  end_time?: number;
  end_reason?: StreamEndReasons;
}

export interface OwnerPositionRawData {
  lat: number;
  lon: number;
}

export interface OwnerPosition {
  lat: number;
  lng: number;
}

export interface StreamOwnerInfo {
  id: string;
  nickname: string;
  avatar: string;
  subscriber_num: number;
}

export interface StreamScheduleInfo {
  start_at: string;
  duration_sec: number;
  max_visitors?: number;
}

export interface StreamsListWithCursor {
  streams: StreamRawData[];
  next: string;
}

export enum StreamSources {
  PHONE = "phone",
  GLASS = "glass",
}

export enum StreamStatus {
  READY = "ready",
  ONLINE = "online",
  OFFLINE = "offline"
}

export interface StreamRawData {
  id: string;
  title: string;
  description: string;
  category: StreamCategories;
  owner_info: StreamOwnerInfo;
  source?: StreamSources;
  price: number;
  price_funds: CommissionFunds;
  private: boolean;
  preview_url: string;
  given_stars?: number;
  purchased?: boolean;
  schedule_info?: StreamScheduleInfo;
  started_at?: string;
  status: StreamStatus,
  ended_at?: string;
  unique_viewers?: number;
  active_viewers?: number;
  /** @deprecated */
  stream: StreamUserRawData;
  /** @deprecated */
  online?: StreamOnlineRawData;
  /** @deprecated */
  schedule?: StreamScheduleRawData;
  /** @deprecated */
  owner: PublicProfileRawData;
  /** @deprecated */
  owner_position?: OwnerPositionRawData;
  /** @deprecated */
  set_stars?: number;
  /** @deprecated */
  chat_room_id?: string;
}
