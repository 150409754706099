import { PrivateProfileRawData } from "types/profile";
import { PrivateProfile } from "./PrivateProfile";

export class CurrentUser extends PrivateProfile {
  isCurrentUser: boolean;

  constructor(data: PrivateProfileRawData) {
    super(data);
    this.isCurrentUser = true;
  }
}
