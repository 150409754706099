import { INTERCOM_APP_ID } from "constants/Env";
import { ModalName } from "constants/Modals";
import { FC, lazy, LazyExoticComponent, Suspense, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { SocialNetworksProviders } from "components/SocialNetworks/SocialNetworksProviders";
import { useAppDispatch, useAuth, useModals } from "hooks";
import { getDictionaryListAsync } from "slices/dictionaryListSlice";
import { getCurrentUserAsync } from "slices/userSlice";
import { getUserWalletBalanceAsync } from "slices/walletSlice";
import { getSubscriptionsAsync } from "slices/subscriptionSlice";
import AuthUtilsService from "services/AuthUtilsService";
import ThemeStore from "stories/ThemeStore";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import LocationHistory from "stories/LocationHistory";
import { Toasts } from "components/Toasts";
import { Cookies } from "components/Cookies";
import Alert from "./Alert/Alert";

const LazyApp: LazyExoticComponent<FC<{ isAuthenticated: boolean }>> = lazy(() => import("./App/App"));

export default observer(() => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const { hideModal, activeModal } = useModals();

  useEffect(() => {
    window.scrollTo(0, 0);
    LocationHistory.pushLocationToHistory(location);

    if (activeModal && ![ModalName.Auth, ModalName.SignUp, ModalName.SignIn].includes(activeModal.name)) {
      hideModal();
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getDictionaryListAsync());
  }, []);

  useEffect(() => {
    if (AuthUtilsService.data.accessToken) {
      dispatch(getCurrentUserAsync());
      dispatch(getUserWalletBalanceAsync());
      dispatch(getSubscriptionsAsync());
    }
  }, [AuthUtilsService.data.accessToken]);

  useEffect(() => {
    (async () => {
      await ThemeStore.setTheme(ThemeStore.activeThemeName);
    })();
  }, []);

  if (!ThemeStore.activeTheme) return;

  return (
    <Suspense fallback={null}>
      <ThemeProvider theme={ThemeStore.activeTheme}>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <AppContainer>
            <SocialNetworksProviders>
              <LazyApp isAuthenticated={isAuthenticated} />
              <Alert />
              <Toasts />
              <Cookies />
            </SocialNetworksProviders>
          </AppContainer>
        </IntercomProvider>
      </ThemeProvider>
    </Suspense>
  );
});

const AppContainer = styled.div`
  width: -webkit-fill-available;
  height: fit-content;
  background-color: ${(props) => props.theme.palette.background.base};
`;
