import { StreamTypes } from "types/streams";

export const getStreamType = (stream) => {
  switch (true) {
    case !!stream.online?.end_time:
      return StreamTypes.Finished;
    case !!stream.online:
      return StreamTypes.Live;
    case !!stream.schedule:
      return StreamTypes.Schedule;
    default:
      return StreamTypes.Private;
  }
};

export const getStreamerIdModified = (id: string) => {
  return `${id}_`;
};
