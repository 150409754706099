import { breakpoints } from "constants/Breakpoints";
import React from "react";
import styled, { css } from "styled-components";
import { TypographyVariant } from "types/theme";

interface TypographyProps {
  isDisabled?: boolean;
  children: React.ReactNode;
  variant?: TypographyVariant;
  color?: string;
  onClick?: () => void;
}

function Typography(props: TypographyProps) {
  const { children, variant = "bodyR16", isDisabled, ...other } = props;

  switch (variant) {
    case "h0":
    case "h1":
      return (
        <H1Container variant={variant} disabled={isDisabled} {...other}>
          {children}
        </H1Container>
      );
    case "h2":
      return (
        <H2Container variant={variant} disabled={isDisabled} {...other}>
          {children}
        </H2Container>
      );
    case "h3":
      return (
        <H3Container variant={variant} disabled={isDisabled} {...other}>
          {children}
        </H3Container>
      );
    default:
      return (
        <BasicContainer variant={variant} disabled={isDisabled} {...other}>
          {children}
        </BasicContainer>
      );
  }
}

export default Typography;

const styles = css<TypographyProps>`
  color: ${(props) => props.color || props.theme.palette.textIcon.basePrimary}};
  ${(props) => {
    const { desktop, mobile } = props.theme.typography;
    return `
      font-size: ${mobile[props.variant].fontSize};
      line-height: ${mobile[props.variant].lineHeight};
      font-weight: ${mobile[props.variant].fontWeight};
      @media ${breakpoints.lg} {
        font-size: ${desktop[props.variant].fontSize};
        line-height: ${desktop[props.variant].lineHeight};
        font-weight: ${desktop[props.variant].fontWeight};
      }
    `;
  }}
`;

const BasicContainer = styled.div<TypographyProps>`
  ${styles}
`;

const H1Container = styled.h1<TypographyProps>`
  ${styles}
`;

const H2Container = styled.h2<TypographyProps>`
  ${styles}
`;

const H3Container = styled.h3<TypographyProps>`
  ${styles}
`;
