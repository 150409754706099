import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: "Pangea", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
    font-variation-settings: "XTDR" 50, "APRT" 100, "SPAC" 40, "SS01" 1, "SS02" 1, "SS03" 1, "INKT" 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0 !important;
    box-sizing: border-box;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  button,
  input,
  p,
  div {
    font-family: "Pangea", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif !important;
    font-variation-settings: "XTDR" 50, "APRT" 100, "SPAC" 40, "SS01" 1, "SS02" 1, "SS03" 1, "INKT" 0 !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  div {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  
  a {
    text-decoration: none;
  }

  .MuiPickersPopper-root {
    .MuiDialogActions-root {
      .MuiButtonBase-root {
        width: 100%;
      }
    }
  }
`;

export default GlobalStyle;
