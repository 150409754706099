import { useCallback, useEffect, useRef } from "react";
import GSChatService from "services/GSChatService";
import audioUrl from "assets/audios/messageNotification.wav";

function changeFavicon(url: string) {
  const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = url;

  document.getElementsByTagName('head')[0].appendChild(link);
}

export const useSoundMessageNotifications = () => {
  const state = useRef({
    isTabActive: true,
    audio: new Audio(audioUrl),
    initialTitle: document.title,
    titleIntervalId: null
  });

  const notify = useCallback(async () => {
    clearInterval(state.current.titleIntervalId);

    if (!state.current.isTabActive) {
      document.title = `(${GSChatService.unreadCount}) новых сообщений в чате`;
      changeFavicon('/notifyFavicon.ico');

      await state.current.audio.play();

      state.current.titleIntervalId = setInterval(() => {
        if (document.title === state.current.initialTitle) {
          document.title = `(${GSChatService.unreadCount}) новых сообщений в чате`;
          changeFavicon('/notifyFavicon.ico');
        } else {
          changeFavicon('/favicon.ico');
          document.title = state.current.initialTitle;
        }
      }, 2000);
    }
  }, [GSChatService.unreadCount])

  useEffect(() => {
    const handleVisibilityChange = () => {
      clearInterval(state.current.titleIntervalId);

      state.current.isTabActive = !document.hidden;

      if (state.current.isTabActive) {
        document.title = state.current.initialTitle;

        changeFavicon('/favicon.ico');
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    };
  }, []);

  return { notify };
};
