import { MINUTES_IN_HOUR } from "constants/App";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import isToday from "dayjs/plugin/isToday";
import duration from "dayjs/plugin/duration";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(duration);

const minBirthdayYear = 1900;
export const globalTimeFormat = "HH:mm";
export const globalDateFormat = "DD.MM.YYYY";
export const apiDateFormat = "YYYY-MM-DD";

export const getUserFriendlyDate = (date: number) => {
  const dm = dayjs(date);

  return {
    date: dm.format(globalDateFormat),
    time: dm.format(globalTimeFormat),
  };
};

export const getUserFriendlyDateCustomFormat = (date: number, format: string) => {
  const dm = dayjs(date);
  return dm.format(format);
};

export const getDifferenceBetweenDates = (date1: number, date2: number) => {
  const secs = Math.floor(dayjs(date1).diff(date2, "second", false));
  return {
    seconds: secs % 60,
    minutes: Math.floor(dayjs(date1).diff(date2, "minute", true)),
    hours: Math.floor(dayjs(date1).diff(date2, "hour", true)),
    days: Math.floor(dayjs(date1).diff(date2, "day", true)),
    week: Math.floor(dayjs(date1).diff(date2, "week", true)),
    month: Math.floor(dayjs(date1).diff(date2, "month", true)),
    year: Math.floor(dayjs(date1).diff(date2, "year", true)),
  };
};

export const getUserFriendlyDifferenceBetweenDates = (date1: number, date2: number) => {
  const { minutes, hours, days, week, month, year } = getDifferenceBetweenDates(date1, date2);
  const getTemplate = (value) => `${value} to start`;

  if (dayjs(date1).diff(date2) < 0) {
    return "Delayed";
  }

  if (minutes < 120) {
    return getTemplate(`${minutes} min${minutes > 1 ? "s" : ""}`);
  }

  if (hours < 24) {
    return getTemplate(`${hours} hour${hours > 1 ? "s" : ""}`);
  }

  if (days < 7) {
    return getTemplate(`${days} day${days > 1 ? "s" : ""}`);
  }

  if (week < 4 || (week === 4 && month === 0)) {
    return getTemplate(`${week} week${week > 1 ? "s" : ""}`);
  }

  if (month < 12) {
    return getTemplate(`${month} month${month > 1 ? "s" : ""}`);
  }

  return getTemplate(`${year} year${year > 1 ? "s" : ""}`);
};

export const checkIsValidBirthday = (b: string) => {
  if (!dayjs(b, apiDateFormat, true).isValid()) {
    return false;
  }

  const birthday = b.split("-");
  const [year] = birthday;

  const birthdayTime = dayjs(b).valueOf();
  const currentTime = dayjs().valueOf();
  const currentYear = dayjs().year();

  if (birthdayTime > currentTime) {
    return false;
  }

  return !(Number(year) > currentYear || Number(year) < minBirthdayYear);
};

export const getAge = (date: string) => {
  const date1 = dayjs(date);
  const date2 = dayjs();
  return date2.diff(date1, "years");
};

export const getCurrentTime = (format: dayjs.UnitType = "millisecond") => {
  return dayjs().get(format);
};

export const convertHoursMinutesToSeconds = (date: Dayjs) => {
  const base = dayjs(date).startOf("minute").valueOf();
  const startOfDay = dayjs(base).startOf("day").valueOf();
  return dayjs.duration(base - startOfDay).asSeconds();
};

export const getCurrentUTCInHours = () => {
  const utcOffsetInHours = dayjs().utcOffset() / MINUTES_IN_HOUR;

  const zeroPrefix = utcOffsetInHours >= 10 ? "" : "0";
  const plusMinusPrefix = utcOffsetInHours < 0 ? "-" : "+";

  return `UTC ${plusMinusPrefix}${zeroPrefix}${Math.abs(utcOffsetInHours)}:00`;
};

export const calculateDateFromUnixTimes = (date: Dayjs, time: Dayjs) => {
  const d = dayjs(date.valueOf()).startOf("day").valueOf();
  const t = dayjs(time).valueOf() - dayjs(time).startOf("day").valueOf();
  return dayjs(d + t)
    .startOf("minute")
    .valueOf();
};

export const getDurationPrettified = (durationInSeconds: number): string => {
  const { minutes, hours, days } = getDifferenceBetweenDates(durationInSeconds * 1000, 0);

  return `
    ${days ? `${days} day(s) ` : ""}
    ${hours - days * 24 ? `${hours - days * 24} hour(s) ` : ""}
    ${minutes - hours * 60 ? `${minutes - hours * 60} minute(s) ` : ""}
  `;
};
