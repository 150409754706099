export const convertCentsToDollars = (cents: number) => {
  return cents / 100;
};

export const convertDollarsToCents = (dollars: number) => {
  return Math.round(dollars * 100);
};

export const showInAppMoneyFormat = (money: number): string => {
  return (Math.floor(money * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
