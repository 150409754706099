import { StreamTokenResponse, User, Stream } from "models";
import { RequestMethod } from "client/BaseHttpClient";
import { StreamRawData, StreamsListWithCursor } from "types/streams";
import { CursorPagination } from "types/request";
import Client from "./Client";

export interface RateStreamRequestData {
  id: Stream["id"];
  stars: User["stars"];
}

interface APIStreamerStreams {
  live: StreamsListWithCursor;
  scheduled: StreamsListWithCursor;
  finished: StreamsListWithCursor;
}

interface APILiveStreams {
  streams: StreamRawData[];
}

interface APIStream {
  private: boolean;
  end_reason: string;
  given_stars: number;
  owner_info: {
    nickname: string;
    id: string;
    avatar: string;
  };
  description: string;
  schedule_info: {
    max_visitors: number;
    start_at: string;
    order_id: string;
    has_payments: boolean;
    duration_sec: number;
  };
  source: string;
  title: string;
  purchased: boolean;
  preview_url: string;
  price: number;
  chat_room_id: string;
  started_at: string;
  id: string;
  category: string;
  ended_at: string;
  unique_viewers: number;
}

interface StreamFilters {
  my?: boolean;
  subscribed?: boolean;
  order?: string;
  limit?: number;
  cursor?: string;
}

export const fetchLiveStreams = (data): Promise<APILiveStreams> =>
  Client.doRequest(`/api/v1/stream/live`, {
    method: RequestMethod.POST,
    data,
  });

export const fetchLiveStreamsPrivate = (data): Promise<APILiveStreams> =>
  Client.doRequest(`/api/v1/stream/pr/live/list`, {
    method: RequestMethod.POST,
    data,
  });

export const fetchScheduledStreams = (data): Promise<APILiveStreams> =>
  Client.doRequest(`/api/v1/stream/scheduled/list`, {
    method: RequestMethod.POST,
    data,
  });

export const fetchScheduledStreamsPrivate = (data): Promise<APILiveStreams> =>
  Client.doRequest(`/api/v1/stream/pr/scheduled/list`, {
    method: RequestMethod.POST,
    data,
  });

export const fetchSubscriptionStreams = ({ cursor, limit, }: CursorPagination): Promise<{ next: string; streams: StreamRawData[] }> =>
  Client.doRequest(`/api/v1/me/subscriptions/streams/actual?${cursor ? `cursor=${cursor}&` : ""}${limit ? `limit=${limit}` : ""}`);

export const fetchSubscriptionStreamsLive = ({ cursor, limit }: CursorPagination): Promise<{ next: string; streams: StreamRawData[] }> =>
  Client.doRequest(`/api/v1/me/subscriptions/streams/live?${cursor ? `cursor=${cursor}&` : ""}${limit ? `limit=${limit}` : ""}`);

export const fetchSubscriptionStreamsScheduled = ({
  cursor,
  limit,
}: CursorPagination): Promise<{ next: string; streams: StreamRawData[] }> =>
  Client.doRequest(`/api/v1/me/subscriptions/streams/scheduled?${cursor ? `cursor=${cursor}&` : ""}${limit ? `limit=${limit}` : ""}`);

export const fetchStreamerStreams = (username: string): Promise<APIStreamerStreams> =>
  Client.doRequest(`/api/v1/paxers/${username}/streams`, {
    method: RequestMethod.GET,
  });

export const fetchViewerStreams = ( { cursor, limit }: CursorPagination): Promise<{ next: string; streams: StreamRawData[] }> =>
  Client.doRequest(`/api/v1/me/paid/streams/actual?${cursor ? `cursor=${cursor}&` : ""}${limit ? `limit=${limit}` : ""}`, {
    method: RequestMethod.GET,
  });

export const fetchStreamerStreamsFinished = (
  username: string,
  { cursor, limit }: CursorPagination,
): Promise<{ next: string; streams: StreamRawData[] }> =>
  Client.doRequest(`/api/v1/paxers/${username}/streams/finished?${cursor ? `cursor=${cursor}&` : ""}${limit ? `limit=${limit}` : ""}`, {
    method: RequestMethod.GET,
  });

export const fetchViewerStreamsFinished = ({ cursor, limit }: CursorPagination,): Promise<{ next: string; streams: StreamRawData[] }> =>
  Client.doRequest(`/api/v1/me/paid/streams/finished?${cursor ? `cursor=${cursor}&` : ""}${limit ? `limit=${limit}` : ""}`, {
    method: RequestMethod.GET,
  });

export const fetchStreamerStreamsPrivate = (id: string): Promise<APIStreamerStreams> =>
  Client.doRequest(`/api/v1/paxers/${id}/streams`, {
    method: RequestMethod.GET,
  });

export const fetchStreamInfo = (streamId): Promise<StreamRawData> =>
  Client.doRequest(`/api/v1/stream/info/${streamId}`, {
    method: RequestMethod.GET,
  });

export const fetchStreamInfoPrivate = (streamId): Promise<StreamRawData> =>
  Client.doRequest(`/api/v1/stream/pr/info/${streamId}`, {
    method: RequestMethod.GET,
  });

export const generateStreamToken = (id): Promise<StreamTokenResponse> =>
  Client.doRequest("/api/v1/stream/token", {
    method: RequestMethod.POST,
    data: id,
  });

export const rateStream = (data: RateStreamRequestData): Promise<StreamRawData> =>
  Client.doRequest("/api/v1/stream/star", {
    method: RequestMethod.POST,
    data,
  });

export const purchaseStream = (streamId: string) =>
  Client.doRequest<StreamRawData>(`/api/v1/streams/${streamId}/stream:buy`, {
    method: RequestMethod.POST,
  });

// new streams

export const getStreamById = (streamId: string) =>
  Client.doRequest<APIStream>(`/api/v1/streams/${streamId}`, {
    method: RequestMethod.GET,
  });

export const updateStreamById = (streamId: string, patch: APIStream) =>
  Client.doRequest<APIStream>(`/api/v1/streams/${streamId}`, {
    method: RequestMethod.PATCH,
    data: patch,
  });

export const updateStreamPreviewById = (streamId: string) =>
  Client.doRequest<APIStream>(`/api/v1/streams/${streamId}/preview`, {
    method: RequestMethod.POST,
  });

export const getStreamsByFilters = (filters: StreamFilters) => {
  const { limit, cursor, ...data } = filters;
  const params = [];

  if (limit) {
    params.push(`limit=${limit}`);
  }

  if (cursor) {
    params.push(`cursor=${cursor}`);
  }

  const paramsString = params.length > 0 ? `?${params.join("&")}` : "";

  return Client.doRequest<APIStream>(`/api/v1/streams:filter${paramsString}`, {
    method: RequestMethod.POST,
    data,
  });
};

export const buyStreamAccessById = (streamId: string) =>
  Client.doRequest<undefined>(`/api/v1/streams/${streamId}/stream:buy`, {
    method: RequestMethod.POST,
  });

export const rateStreamById = (streamId: string, rate: number) =>
  Client.doRequest<undefined>(`/api/v1/streams/${streamId}/stream:rate`, {
    method: RequestMethod.POST,
    data: { rate },
  });
