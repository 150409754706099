import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { ReactComponent as CloseSVG } from "../../assets/close.svg";

interface BaseDialogProps {
  isOpen: boolean;
  close: () => void;
  open: (title: any, contentText: any, buttonTitle: any) => void;
  title: string;
  contentText: string;
  buttonTitle?: string;
}

function BaseDialog(props: BaseDialogProps) {
  const { isOpen, close, title = "", contentText = "", buttonTitle = "OK" } = props;
  return (
    <Dialog open={isOpen} onClose={close} aria-labelledby="adialog-title" aria-describedby="dialog-description">
      <DialogWrap>
        <IconButtonWrap>
          <IconButton onClick={close}>
            <CloseSVG />
          </IconButton>
        </IconButtonWrap>
        <DialogTitle>
          <Title>{title}</Title>
        </DialogTitle>
        <Content>
          <ContentText>{contentText}</ContentText>
        </Content>
        <Actions>
          <StyledButton variant="contained" onClick={close} autoFocus>
            {buttonTitle}
          </StyledButton>
        </Actions>
      </DialogWrap>
    </Dialog>
  );
}

const DialogWrap = styled.div`
  max-width: 320px;
  height: 244px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const IconButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-bottom: 10px;
`;

const Title = styled(Typography)`
  font-weight: 700 !important;
  font-size: 24px !important;
`;

const Content = styled.div``;

const ContentText = styled(Typography)`
  text-align: center;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 18px;
`;

const Actions = styled(DialogActions)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export default BaseDialog;
