import { breakpoints } from "constants/Breakpoints";
import { FC, useEffect, useState } from 'react';
import styled from "styled-components";
import Button from "_ui-kit/components/Button";
import Typography from "_ui-kit/components/Typography";
import { ReactComponent as CookiesSVG } from "assets/cookies.svg";

export const Cookies: FC = () => {
  const [accepted, setAccepted] = useState(true);

  useEffect(() => {
    setAccepted(!!localStorage.getItem('cookies-accepted') || false);
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookies-accepted', "true");
    setAccepted(true);
  };

  const handleDecline = () => {
    localStorage.setItem('cookies-accepted', "false");
    setAccepted(true);
  };

  return !accepted ? (
    <Container>
      <Info>
        <Icon>
          <CookiesSVG />
        </Icon>
        <Typography>
          Our website use cookies. By continuing navigating, we assume your permission
          to deploy cookies as detailed in our <a href="/privacy-policy" target="_blank">Privacy Policy</a>.
        </Typography>
      </Info>
      <Controls>
        <ControlButton variant="secondaryStroke" onClick={handleDecline}>Decline</ControlButton>
        <ControlButton onClick={handleAccept}>Accept cookies</ControlButton>
      </Controls>
    </Container>
  ) : null;
}

const Container = styled.div`
  position: fixed;
  z-index: 1101;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 16px;
  background-color: ${({ theme }) => theme.palette.background.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @media ${breakpoints.md} {
    bottom: auto;
    top: 0;
    padding: 24px;
    flex-direction: row;
    gap: 24px;
  }

  @media ${breakpoints.lg} {
    padding: 24px 40px;
    gap: 34px;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  
  a {
    color: ${({ theme }) => theme.palette.textIcon.buttonDefault};
    
    &:hover {
      color: ${({ theme }) => theme.palette.textIcon.buttonHoverFocus};
    }
  }

  @media ${breakpoints.md} {
    gap: 24px;
  }
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  width: 100%;

  @media ${breakpoints.md} {
    width: auto;
  }
`;

const ControlButton = styled(Button)`
  flex: 1;

  @media ${breakpoints.md} {
    flex: 0;
  }
`;

