import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";
import { ResponseStatus } from "types";
import { Organization } from "models/Organization";
import { getOrganizationsList } from "services/OrganizationService";

const BARCELONA = "barcelona";

export interface OrganizationsState {
  organizationsList: Organization[];
  organizationsListStatus: ResponseStatus;
}

const initialState: OrganizationsState = {
  organizationsListStatus: ResponseStatus.IDLE,
  organizationsList: null,
};

export const getOrganizationsListAsync = createAsyncThunk("auth/getOrganizationsListAsync", async () => {
  const response = await getOrganizationsList();
  const organizationsList = response.orgs.map((organization) => new Organization(organization));

  return organizationsList;
});

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationsListAsync.pending, (state) => {
        state.organizationsListStatus = ResponseStatus.LOADING;
      })
      .addCase(getOrganizationsListAsync.fulfilled, (state, action) => {
        state.organizationsListStatus = ResponseStatus.IDLE;
        state.organizationsList = action.payload;
      })
      .addCase(getOrganizationsListAsync.rejected, (state) => {
        state.organizationsListStatus = ResponseStatus.FAILED;
      });
  },
});

export const organizationsSelectors = {
  barcelonaOrganization: (state: RootState) =>
    state.organization?.organizationsList?.find((organization) => organization.orgsName === BARCELONA),
  isOrganizationsLoading: (state: RootState) => state.organization.organizationsListStatus === ResponseStatus.LOADING,
};

export default organizationSlice.reducer;
