import React from "react";
import Dialog from "../Dialog/Dialog";

interface IAlertProps {}

interface IAlertState {
  isOpen: boolean;
  title: string;
  contentText: string;
  buttonTitle?: string;
}

class Alert extends React.PureComponent<IAlertProps, IAlertState> {
  constructor(props) {
    super(props);

    this.state = { isOpen: false, title: "", contentText: "" };
  }

  public static self;

  componentDidMount() {
    Alert.self = {
      handleOpen: this.handleOpen,
      handleClose: this.handleClose,
    };
  }

  componentWillUnmount() {
    Alert.self = null;
  }

  handleClose = () => {
    this.setState({ isOpen: false, title: "", contentText: "", buttonTitle: "" });
  };

  handleOpen = (title, contentText, buttonTitle) => {
    this.setState({ isOpen: true, title, contentText, buttonTitle });
  };

  render() {
    return (
      <Dialog
        isOpen={this.state.isOpen}
        open={this.handleOpen}
        close={this.handleClose}
        title={this.state.title}
        contentText={this.state.contentText}
      />
    );
  }
}

export default Alert;
