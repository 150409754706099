import { User } from "models";
import { RequestMethod } from "client/BaseHttpClient";
import Client from "./Client";

export interface APIAgoraChatTokenData {
  username: User["username"];
  token: string;
  exp: number;
}

export const fetchChatToken = (): Promise<APIAgoraChatTokenData> =>
  Client.doRequest(`/api/v1/chats/chat_token`, {
    method: RequestMethod.GET,
  });

export const fetchAnonChatToken = (): Promise<APIAgoraChatTokenData> =>
  Client.doRequest(`/api/v1/chats/anon_chat_token`, {
    method: RequestMethod.GET,
  });
