import { breakpoints } from "constants/Breakpoints";
import { FC, forwardRef, HTMLProps } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as SpinnerSVG } from "assets/spinner.svg";
import Typography, { TypographyVariant } from "../Typography";

type ButtonCustomVariants = "primary" | "secondaryFill" | "secondaryStroke" | "tertiary";

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  isDisabled?: boolean;
  isLoading?: boolean;
  variant?: ButtonCustomVariants;
  typographyVariant?: TypographyVariant;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const Button: FC<ButtonProps> = forwardRef((props, ref) => {
  const {
    children,
    startIcon,
    endIcon,
    type = "button",
    typographyVariant = "bodyM16",
    variant = "primary",
    isDisabled,
    isLoading,
    ...rest
  } = props;

  return (
    <StyledButton ref={ref} type={type} variant={variant} disabled={isDisabled || isLoading} isLoading={isLoading} {...rest}>
      {startIcon}
      {isLoading ? (
        <Spinner />
      ) : (
        <Typography variant={typographyVariant} color="inherit">
          {children}
        </Typography>
      )}
      {endIcon}
    </StyledButton>
  );
});

const primaryButtonVariantStyles = css`
  background-color: ${({ isLoading, theme }) => (isLoading ? theme.palette.button.primaryDisabled : theme.palette.button.primaryDefault)};
  color: ${({ isLoading, theme }) => (isLoading ? theme.palette.textIcon.baseQuaternary : theme.palette.stable.white)};
  border: none;

  :hover {
    background-color: ${(props) => props.theme.palette.button.primaryHoverFocus};
  }

  :active {
    background-color: ${(props) => props.theme.palette.button.primaryPressed};
  }

  :disabled {
    color: ${(props) => props.theme.palette.textIcon.baseQuaternary};
    background-color: ${(props) => props.theme.palette.button.primaryDisabled};
  }
`;

const secondaryFillButtonVariantStyles = css`
  background-color: ${({ theme }) => theme.palette.button.secondaryDefault};
  color: ${({ isLoading, theme }) => (isLoading ? theme.palette.textIcon.baseQuaternary : theme.palette.textIcon.basePrimary)};
  border: none;

  :hover {
    color: ${({ theme }) => theme.palette.textIcon.buttonHoverFocus};
  }

  :active {
    background-color: ${({ theme }) => theme.palette.button.secondaryPressed};
    color: ${({ theme }) => theme.palette.textIcon.basePrimary};
  }

  :disabled {
    background-color: ${({ theme }) => theme.palette.button.secondaryDisabled};
    color: ${({ theme }) => theme.palette.textIcon.baseQuaternary};
  }
`;

const secondaryStrokeButtonVariantStyles = css`
  background-color: ${({ theme }) => theme.palette.button.secondaryDefault};
  color: ${({ isLoading, theme }) => (isLoading ? theme.palette.textIcon.baseQuaternary : theme.palette.textIcon.basePrimary)};
  border: 1px solid ${({ isLoading, theme }) => (isLoading ? theme.palette.stroke.brandDisabled : theme.palette.stroke.brandDefault)};

  :hover {
    color: ${({ theme }) => theme.palette.textIcon.buttonHoverFocus};
    border-color: ${({ theme }) => theme.palette.stroke.brandHoverFocus};
  }

  :active {
    background-color: ${({ theme }) => theme.palette.button.secondaryPressed};
    color: ${({ theme }) => theme.palette.textIcon.basePrimary};
    border: 1px solid ${({ theme }) => theme.palette.stroke.brandPressed};
  }

  :disabled {
    background-color: ${({ theme }) => theme.palette.button.secondaryDisabled};
    color: ${({ theme }) => theme.palette.textIcon.baseQuaternary};
    border-color: ${({ theme }) => theme.palette.stroke.brandDisabled};
  }
`;

const tertiaryButtonVariantStyles = css`
  background-color: transparent;
  color: ${({ theme, isLoading }) => (isLoading ? theme.palette.textIcon.baseQuaternary : theme.palette.textIcon.basePrimary)};
  border: none;

  :hover {
    background-color: ${({ theme }) => theme.palette.button.tertiaryHoverFocus};
  }

  :active {
    background-color: ${({ theme }) => theme.palette.button.tertiaryPressed};
  }

  :disabled {
    color: ${({ theme }) => theme.palette.textIcon.baseQuaternary};
  }
`;

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;

  :disabled {
    font-style: normal;
    cursor: default;
  }

  ${({ variant }) => variant === "primary" && primaryButtonVariantStyles}
  ${({ variant }) => variant === "tertiary" && tertiaryButtonVariantStyles}
  ${({ variant }) => variant === "secondaryFill" && secondaryFillButtonVariantStyles}
  ${({ variant }) => variant === "secondaryStroke" && secondaryStrokeButtonVariantStyles}

  @media ${breakpoints.lg} {
    padding: 16px 24px;
  }
`;

const Spinner = styled(SpinnerSVG)`
  animation: 1.5s linear infinite spin;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default Button;
