import { StreamEndReasons } from "./streams";
import { UserRole } from './users';
import { CommissionFunds } from "./transaction";

export enum OrderStatus {
  CREATED = "created",
  CONFIRMED = "confirmed",
  CHANGED = "changed",
  ACCEPTED = "accepted",
  PAID_FOR = "paid_for",
  STARTED = "started",
  COMPLETED = "completed",
  PAID_OUT = "paid_out",
  CANCELED = "canceled",
  CONFIRMATION_EXPIRED = "confirmation_expired",
  START_EXPIRED = "start_expired",
  PAYMENT_EXPIRED = "payment_expired",
}

export interface User {
  id: string;
  username: string;
  avatar_url: string;
}

export interface Order {
  id: string;
  stream_start_time: string;
  duration: number;
  description: string;
  location: string;
  rate: number;
  rate_funds: CommissionFunds;
  price_funds: CommissionFunds;
  price: number;
  paxer: User;
  beholder: User;
  created_at: string;
  updated_at: string;
  status: OrderStatus;
  chat_id: string;
  scheduled_stream_id?: string;
  is_express?: boolean;
  pay_before?: string;
  ending_reason?: StreamEndReasons;
  total_price?: number;
  extra_price?: number;
  extra_time_sec?: number;
  scheduled_payout_at?: string;
}

export interface OrderAttachment {
  type: "order";
  previous: Order | null;
  order: Order;
}

export enum OrderSystemMessageCTA {
  CONTACT_TO_SUPPORT = "contact_to_support",
  GO_TO_STREAM = "go_to_stream"
}

export enum OrderMessageUIType {
  BUTTON = "button"
}

export interface OrderMessageInteraction {
  cta: OrderSystemMessageCTA;
  display_for: Array<UserRole>;
  type: OrderMessageUIType;
}

export interface OrderMessagePayload {
  display_for: Array<UserRole>,
  interactions: OrderMessageInteraction[],
  order_id: string;
  stream_id?: string;
}

export interface OrderProlongationRawData {
  available: boolean;
  price: number;
  price_funds: CommissionFunds;
  duration_sec: number;
}

export enum OrderTypes {
  EXPRESS = "express",
  SCHEDULED = "scheduled"
}

export interface OrdersSettingsRawData {
  free_cancellations_left: number;
}
