import { Option } from "types/common";

export const normalizeDictionary = (dictionaryItem) =>
  dictionaryItem.reduce((acc, item) => {
    acc[item.code] = item;
    return acc;
  }, {});

export const getOptionsListLabelByValue = (optionsList: Option[], value: number | string) => {
  const selectedOption = optionsList.find((option) => option.value === value);

  return selectedOption.label;
};

export const openFullscreen = (elem) => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  } else {
    throw new Error("Your browser doesnt support fullscreen");
  }
}
