import { GETSTREAM_CHAT_API_KEY } from "constants/Env";
import { action, makeObservable, observable } from "mobx";
import { Channel, OwnUserResponse, StreamChat, UserResponse } from "stream-chat";
import { getCurrentChatUser } from "services/ProfileService";

class GSChatService {
  @observable public client: StreamChat<any> | null = null;
  @observable public unreadCount: number = 0;
  @observable public channelsLoading = false;
  @observable public channels: Channel[] = [];

  private listeners: Record<string, any> = {};

  private user: OwnUserResponse | UserResponse | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  public async connect(user: OwnUserResponse | UserResponse) {
    if (this.client) return;

    const chatClient = new StreamChat(GETSTREAM_CHAT_API_KEY, {
      enableInsights: true,
      enableWSFallback: true,
    });

    await getCurrentChatUser().then(async ({ token }) => {
      await chatClient.connectUser(user, token)
        .then(() => {
          this.client = chatClient;
          this.user = user;

          this.getChannels();
          this.updateUnreads();
        })
        .catch((e) => {
          console.error(`Failed to connect user`, e);
        });

      this.client.on("message.read", this.updateUnreads);
    }).catch((e) => {
      console.log("Failed to get current chat user:", e);
    });
  };

  @action
  public async getChannels(): Promise<void> {
    this.channelsLoading = true;

    this.client.queryChannels({ type: 'order', members: { $in: [this.user.id] } }).then((channels) => {
      this.channels = channels;
    }).catch(async (e) => {
      console.warn("Failed to fetch getstream chat channels", e);

      const user = this.user;

      await this.disconnect();
      await this.connect(user);
    }).finally(() => {
      this.channelsLoading = false;
    });
  }

  @action
  public async updateUser(options: Partial<UserResponse>) {
    return await this.client.partialUpdateUser({
      id: this.user.id,
      set: options,
    }).catch((e) => {
      console.warn("Failed to update getstream user data", e);
    });
  }

  @action
  public async disconnect() {
    await this.client?.disconnectUser().catch((e) => {
      console.error(`Failed to disconnect user`, e);
    });

    this.user = null;
    this.client = null;
    this.channels = [];
  };

  @action
  public updateUnreads = async () => {
    this.unreadCount = (await this.client.getUnreadCount()).total_unread_count;
  };

  @action
  public subscribe = async (action: string, callback: any) => {
    if (!this.listeners[action]) {
      if (this.client) {
        this.client.listeners[action] = [];
        this.client?.on(action, callback);
      }

      this.listeners[action] = callback;
    }
  };

  @action
  public unsubscribe = async (action: string, callback: any) => {
    if (this.listeners[action]) {
      if (this.client) {
        this.client.off(action, callback);
        this.client.listeners[action] = [];
      }

      delete this.listeners[action];
    }
  };
}

export default new GSChatService();
