import { breakpointValues } from "constants/Breakpoints";
import { Theme } from "types/theme";
import { ThemeName } from "types";

export const initializeTheme = async (name: ThemeName): Promise<Theme> => {
  const theme = await import(`./${name}`);

  return {
    name,
    palette: theme.palette,
    typography: theme.typography,
    breakpoints: breakpointValues,
  };
};
