import { StreamCategories } from "types/streams";
import { PublicProfileRawData } from "types/profile";
import { CommissionFunds } from "types/transaction";

export class PublicProfile {
  id: string;
  isPaxer: boolean;
  username: string;
  stars?: number;
  reviewCount?: number;
  subscriberCount?: number;
  orderCount?: number;
  lastStreamCategory?: StreamCategories;
  avatarURL?: string;
  bio?: string;
  skills?: Array<string>;
  rate?: number;
  rate_funds?: CommissionFunds;
  locationCity: string;
  locationCountry: string;
  locationDisplay: string;
  locationId: string;
  organizationAvatarURL?: string;
  organizationName?: string;
  bannerUrl?: string;
  seoContent?: string;
  totalPaidOrders?: number;
  readyForOrder?: boolean;
  languages?: string[];

  constructor(data: PublicProfileRawData) {
    let subscribers = 0;

    if (data.paxer?.subscribers) {
      if (data.paxer.subscribers > subscribers) subscribers = data.paxer.subscribers;
    }

    this.id = data.id;
    this.isPaxer = !!data.paxer;
    this.readyForOrder = data.paxer?.ready_for_order || data.ready_for_order || false;
    this.languages = data.paxer?.languages || [];
    this.username = data.nickname || data.username;
    this.stars = data.paxer?.rating || data.stars || 0;
    this.reviewCount = data.paxer?.reviews || data.review_num || 0;
    this.subscriberCount = subscribers || data.subscriber_num;
    this.orderCount = data.order_num || 0;
    this.lastStreamCategory = data.paxer?.last_stream_category || data.last_stream_category;
    this.avatarURL = data.avatar || data.avatar_url || null;
    this.bio = data.paxer?.bio || data.bio;
    this.skills = data.paxer?.skills || data.skills;
    this.rate = data.paxer?.rate || data.rate;
    this.rate_funds = data.paxer?.rate_funds || data.rate_funds;
    this.locationCity = data.paxer?.location?.city || data.location?.city || "";
    this.locationCountry = data.paxer?.location?.country || data.location?.country || "";
    this.locationDisplay = data.paxer?.location?.display_name || data.location?.display_name || "";
    this.locationId = data.paxer?.location?.location_id || data.location?.location_id || "";
    this.organizationAvatarURL = data.organisation?.avatar_url || "";
    this.organizationName = data.organisation?.username || "";
    this.bannerUrl = data.paxer?.banner_url || null;
    this.seoContent = data.paxer?.seo_content || "";
    this.totalPaidOrders = data.paxer?.total_paid_orders || data.total_paid_orders || 0;
  }
}
