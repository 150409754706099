import { LOCAL_STORAGE_PREFIX } from "constants/App";
import { action, makeObservable, observable } from "mobx";

interface IAuthStorage {
  accessToken: string | null;
  refreshToken: string | null;
  login?: string | null;
}

class AuthUtilsService {
  @observable
  public data: IAuthStorage = {
    accessToken: null,
    refreshToken: null,
    login: null
  };

  constructor() {
    makeObservable(this);

    Object.keys(this.data).forEach((key) => {
      const value = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${key}`);

      try {
        this.data[key] = JSON.parse(value);
      } catch (e) {
        this.data[key] = value || null;
      }
    });
  }

  @action
  public setAuthMetadata(data: IAuthStorage) {
    Object.keys(data).forEach((key: keyof IAuthStorage) => {
      if (!(key in this.data)) {
        console.warn(`Unexpected auth key ${key}`);
      } else {
        const value = data[key];

        if (value) {
          this.data[key] = value;

          localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${key}`, value);
        }
      }
    });
  }

  @action
  public clearAuthMetadata() {
    Object.keys(this.data).forEach((key) => {
      this.data[key] = null;

      localStorage.removeItem(`${LOCAL_STORAGE_PREFIX}${key}`);
    });
  }
}

export default new AuthUtilsService();
