import { BASE_URL } from "constants/Env";
import Client from "client/Client";
import * as AuthService from "services/AuthService";
import AuthUtilsService from "services/AuthUtilsService";

let accessTokenPromise: Promise<void> = null;

export const tryToUpdateAccessToken = async () => {
  if (!accessTokenPromise) {
    accessTokenPromise =
      AuthService.tryToUpdateAccessToken()
        .then((response) => {
          AuthUtilsService.setAuthMetadata({
            accessToken: response.access_token,
            refreshToken: response.refresh_token,
          });
        })
        .catch(() => {
          // if (error.status === 401) {
          AuthUtilsService.clearAuthMetadata();
          // }
        }).finally(() => {
          accessTokenPromise = null;
        });
  }

  return accessTokenPromise;
};

const getAccessToken = () => AuthUtilsService.data.accessToken;

export default new Client(BASE_URL, getAccessToken, tryToUpdateAccessToken);
