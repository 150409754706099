import { ModalName } from "constants/Modals";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";

export interface ModalSettings {
  name: ModalName;
  props?: any;
  onClose?: () => void;
  onConfirm?: () => void;
}

export interface ModalState {
  active: ModalSettings | null;
}

const initialState: ModalState = {
  active: null,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    show: (state, action) => {
      state.active = action.payload;
    },
    hide: (state) => {
      state.active = null;
    },
  },
});

export const modalSelectors = {
  active: (state: RootState) => state.modal.active,
};

export const { show, hide } = modalSlice.actions;

export default modalSlice.reducer;
