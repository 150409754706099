export const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u;
export const EMAIL_LATIN_CHARACTERS_REGEXP = /^[A-Za-z0-9+-.]+@[A-Za-z]+.[A-Za-z]+$/gm;

export const EMAIL_IS_INVALID_ERROR = "Email address should look like mail@example.com";

export const EMAIL_LATIN_CHARACTERS_ERROR = "Email address must consist of Latin characters";

export const MINUTES_IN_HOUR = 60;

export const SECONDS_IN_MINUTE = 60;

export const LOCAL_STORAGE_PREFIX = "mpax-";

export const SUPPORT_EMAIL = "support@mpax.com";

export enum SocialNetworkProvider {
  Google = "google",
  Facebook = "facebook",
  Twitter = "twitter",
  Apple = "apple",
  Telegram = "telegram"
}

export enum Platform {
  IOS = "ios",
  Android = "android",
  WEB = "web",
}
