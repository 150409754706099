import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";
import {
  login,
  authSocialNetwork,
  sendAuthVerificationCode,
  sendSignInVerificationCode,
  sendSignUpVerificationCode,
  SocialNetworkRequest,
} from "services/AuthService";
import AuthUtilsService from "services/AuthUtilsService";
import { ResponseStatus } from "types";

export interface AuthState {
  code: string | null;
  status: ResponseStatus;
  login: string | null;
}

const initialState: AuthState = {
  code: null,
  status: ResponseStatus.IDLE,
  login: null,
};

export const sendSignInVerificationCodeAsync = createAsyncThunk("auth/sendSignInVerificationCodeAsync", async (email: string) => {
  await sendSignInVerificationCode(email);
});

export const sendSignUpVerificationCodeAsync = createAsyncThunk("auth/sendSignUpVerificationCodeAsync", async (email: string) => {
  await sendSignUpVerificationCode(email);
});

export const sendAuthVerificationCodeAsync = createAsyncThunk("auth/sendAuthVerificationCodeAsync", async (email: string) => {
  await sendAuthVerificationCode(email);
});

export const loginAsync = createAsyncThunk("auth/loginAsync", async ({ email, code }: { email: string; code: string }) => {
  const response = await login(email, code);
  const { access_token, refresh_token } = response;

  AuthUtilsService.setAuthMetadata({
    accessToken: access_token,
    refreshToken: refresh_token,
    login: email,
  });

  return response;
});

export const loginOAuth2Async = createAsyncThunk(
  "auth/loginOAuth2Async",
  async (data: { email?: string; request: SocialNetworkRequest }) => {
    const { email, request } = data;
    const response = await authSocialNetwork(request);
    const { access_token, refresh_token } = response;

    AuthUtilsService.setAuthMetadata({
      accessToken: access_token,
      refreshToken: refresh_token,
      login: email,
    });

    return response;
  },
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.login = action.payload;
    },
    clearAuthData: (state) => {
      state.login = null;

      AuthUtilsService.clearAuthMetadata();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendSignInVerificationCodeAsync.pending, (state) => {
        state.status = ResponseStatus.LOADING;
      })
      .addCase(sendSignInVerificationCodeAsync.fulfilled, (state, action) => {
        state.status = ResponseStatus.IDLE;
        // FIXME Неочевидно
        state.login = String(action.meta.arg);
      })
      .addCase(sendSignInVerificationCodeAsync.rejected, (state) => {
        state.status = ResponseStatus.FAILED;
      })
      .addCase(sendSignUpVerificationCodeAsync.pending, (state) => {
        state.status = ResponseStatus.LOADING;
      })
      .addCase(sendSignUpVerificationCodeAsync.fulfilled, (state, action) => {
        state.status = ResponseStatus.IDLE;
        // FIXME Неочевидно
        state.login = String(action.meta.arg);
      })
      .addCase(sendSignUpVerificationCodeAsync.rejected, (state) => {
        state.status = ResponseStatus.FAILED;
      })
      .addCase(loginAsync.pending, (state) => {
        state.status = ResponseStatus.LOADING;
      })
      .addCase(loginAsync.fulfilled, (state) => {
        state.status = ResponseStatus.IDLE;
      })
      .addCase(loginAsync.rejected, (state) => {
        state.status = ResponseStatus.FAILED;
      })
      .addCase(loginOAuth2Async.pending, (state) => {
        state.status = ResponseStatus.LOADING;
      })
      .addCase(loginOAuth2Async.fulfilled, (state) => {
        state.status = ResponseStatus.IDLE;
      })
      .addCase(loginOAuth2Async.rejected, (state) => {
        state.status = ResponseStatus.FAILED;
      });
  },
});

export const authSelectors = {
  login: (state: RootState) => state.auth.login,
  status: (state: RootState) => state.auth.status,
};

export const { loginSuccess, clearAuthData } = authSlice.actions;
export default authSlice.reducer;
