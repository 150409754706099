import { ResponseStatus } from "types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { subscribe, unsubscribe, getSubscriptions } from "services/SubscriptionService";
import { PublicProfile } from "models";
import type { RootState } from "store";

export interface SubscriptionState {
  subscribeStreamerStatus: ResponseStatus | null;
  unsubscribeStreamerStatus: ResponseStatus | null;
  subscriptionsStatus: ResponseStatus | null;
  subscriptions: Array<PublicProfile>;
}

const initialState: SubscriptionState = {
  subscribeStreamerStatus: null,
  unsubscribeStreamerStatus: null,
  subscriptionsStatus: null,
  subscriptions: [],
};

export const subscribeAsync = createAsyncThunk("auth/subscribeAsync", async (username: PublicProfile["username"]) => {
  const response = await subscribe(username);

  if (response.profiles) {
    return response.profiles.map((profile) => new PublicProfile(profile));
  }

  return [];
});

export const unsubscribeAsync = createAsyncThunk("auth/unsubscribeAsync", async (username: PublicProfile["username"]) => {
  const response = await unsubscribe(username);

  if (response.profiles) {
    return username;
  }
});

export const getSubscriptionsAsync = createAsyncThunk("auth/getSubscriptionsAsync", async () => {
  const response = await getSubscriptions();

  if (response.profiles) {
    return response.profiles.map((profile) => new PublicProfile(profile));
  }
});

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(subscribeAsync.pending, (state) => {
        state.subscribeStreamerStatus = ResponseStatus.LOADING;
      })
      .addCase(subscribeAsync.fulfilled, (state, action) => {
        state.subscribeStreamerStatus = ResponseStatus.IDLE;
        state.subscriptions = [...action.payload];
      })
      .addCase(subscribeAsync.rejected, (state) => {
        state.subscribeStreamerStatus = ResponseStatus.FAILED;
      })
      .addCase(unsubscribeAsync.pending, (state) => {
        state.unsubscribeStreamerStatus = ResponseStatus.LOADING;
      })
      .addCase(unsubscribeAsync.fulfilled, (state, action) => {
        state.unsubscribeStreamerStatus = ResponseStatus.IDLE;
        state.subscriptions = state.subscriptions.filter((streamer) => action.payload !== streamer.username);
      })
      .addCase(unsubscribeAsync.rejected, (state) => {
        state.unsubscribeStreamerStatus = ResponseStatus.FAILED;
      })
      .addCase(getSubscriptionsAsync.pending, (state) => {
        state.subscriptionsStatus = ResponseStatus.LOADING;
      })
      .addCase(getSubscriptionsAsync.fulfilled, (state, action) => {
        state.subscriptionsStatus = ResponseStatus.IDLE;
        state.subscriptions = action.payload ? [...action.payload] : [];
      })
      .addCase(getSubscriptionsAsync.rejected, (state) => {
        state.subscriptionsStatus = ResponseStatus.FAILED;
      });
  },
});

export const subscriptionSelectors = {
  subscriptions: (state: RootState) => state.subscription.subscriptions,
  subscribeStatus: (state: RootState) => state.subscription.subscribeStreamerStatus,
  unsubscribeStatus: (state: RootState) => state.subscription.unsubscribeStreamerStatus,
};

export default subscriptionSlice.reducer;
