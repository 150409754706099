import { useNavigate as useNavigateRouterDom, useLocation, NavigateFunction, Location, useParams } from "react-router-dom";

interface NavigateResults {
  navigate: NavigateFunction;
  location: Location;
  params: Record<string, string>;
}

export const useNavigate = (): NavigateResults => {
  const navigate = useNavigateRouterDom();
  const location = useLocation();
  const params = useParams();
  return { navigate, location, params };
};
