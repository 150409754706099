import { GOOGLE_OAUTH_CLIENT_ID } from "constants/Env";
import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

interface SocialNetworksProvidersProps {
  children: React.ReactNode;
}

export const SocialNetworksProviders: React.FC<SocialNetworksProvidersProps> = (props) => {
  if (!GOOGLE_OAUTH_CLIENT_ID) return <>{props.children}</>;
  return <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>{props.children}</GoogleOAuthProvider>;
};
