import { RequestMethod } from "client/BaseHttpClient";
import { OrganizationRawData } from "models/Organization";
import Client from "./Client";

interface OrganizationsListResponse {
  orgs: OrganizationRawData[];
}

export const getOrganizationsList = (): Promise<OrganizationsListResponse> =>
  Client.doRequest(`/api/v1/orgs`, {
    method: RequestMethod.GET,
  });
