import { show, hide, ModalSettings, modalSelectors } from "slices/modalSlice";
import { useAppDispatch, useAppSelector } from "hooks";

export interface ModalsResult {
  activeModal: ModalSettings;
  showModal: (settings: ModalSettings) => void;
  hideModal: () => void;
}

export const useModals = (): ModalsResult => {
  const dispatch = useAppDispatch();
  const activeModal = useAppSelector(modalSelectors.active);

  const showModal = (settings: ModalSettings) => {
    dispatch(show(settings));
  };

  const hideModal = () => {
    dispatch(hide());
  };

  return { activeModal, showModal, hideModal };
};
