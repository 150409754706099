import { ReactComponent as BinanceIcon } from "assets/binanceIcon.svg";
import { ReactComponent as PaypalIcon } from "assets/paypalIcon.svg";

export const paymentMethodDictionary = {
  pay_pal: { name: "Pay Pal", icon: <PaypalIcon /> },
  binance: { name: "Binance", icon: <BinanceIcon /> },
};

export const paymentTypeDictionary = {
  top_up: "Top Up",
  withdraw: "Withdraw",
  donate: "Donate",
  subscribe: "Subscribe",
  paid_stream: "Paid Stream",
};

export enum PaymentTypes {
  TopUp = "top_up",
  Withdraw = "withdraw",
  Donate = "donate",
  Subscribe = "subscribe",
  PaidStream = "paid_stream",
}
