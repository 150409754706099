import once from "lodash/once";
import BaseHttpClient, { TOptions, THeaders, BaseHttpError, TAfterRequestFn } from "./BaseHttpClient";

interface IOptions extends TOptions {
  headers?: THeaders;
  omitDefaultErrorHandling?: TOmitDefaultErrorHandling;
}

export class ClientError extends Error {
  name = "ClientError";

  message: string;
  status: number;
  kind: string;

  constructor(err: BaseHttpError) {
    super(err.message);
    const { status, decodedResponse } = err;

    this.status = status;
    this.kind = decodedResponse?.kind || decodedResponse?.error_code || "";
    this.message = decodedResponse?.message || "";
  }
}

export type TOmitDefaultErrorHandling = ((err: ClientError) => boolean) | boolean;

type TGlobalErrorHandler = (err: ClientError, omitDefaultErrorHandling: TOmitDefaultErrorHandling) => void;

type TGetAccessToken = () => string;
type TTryToUpdateAccessToken = () => Promise<any>;

const { doRequest } = BaseHttpClient.prototype;

// const MAX_UPDATE_ACCESS_TOKEN_REQUESTS = 5;

class Client extends BaseHttpClient {
  protected getAccessToken: TGetAccessToken;

  protected tryToUpdateAccessToken: TTryToUpdateAccessToken;

  protected teardown() {
    for (const key in this.abortControllers) {
      this.abort(this.abortControllers[key]);
    }
  }

  protected hasAccessToken(): boolean {
    return Boolean(this.getAccessToken());
  }

  protected updateAccessTokenCount: number = 0;

  protected afterRequest: TAfterRequestFn = ({ options, err }) => {
    if (err) {
      const newClientError = new ClientError(err);

      const emitErrors = () => {
        this.globalErrorHandler(newClientError, options.omitDefaultErrorHandling);
        throw newClientError;
      };

      // if (newClientError.status === 401 && this.hasAccessToken()) {
      //   if (++this.updateAccessTokenCount > MAX_UPDATE_ACCESS_TOKEN_REQUESTS) {
      //     this.teardown();
      //   } else {
      //     // this.tryToUpdateAccessToken()
      //       // .then(async () => {
      //       //   const accessToken = this.getAccessToken();
      //       //   const response = await retryOriginRequest(accessToken ? {
      //       //     headers: {
      //       //       Authorization: `Bearer ${accessToken}`,
      //       //     },
      //       //   } : {});
      //       //   this.updateAccessTokenCount = 0;
      //       //
      //       //   return response;
      //       // })
      //       // .catch(() => {
      //       //   this.teardown();
      //       // });
      //   }
      // }

      emitErrors();
    }
  };

  constructor(baseUrl: string, getAccessToken: TGetAccessToken, tryToUpdateAccessToken: TTryToUpdateAccessToken) {
    super(baseUrl);
    this.getAccessToken = getAccessToken;
    this.tryToUpdateAccessToken = tryToUpdateAccessToken;
  }

  protected getOwnHeaders(): THeaders {
    const accessToken = this.getAccessToken();

    return {
      ...(accessToken ? {
        Authorization: `Bearer ${this.getAccessToken()}`
      } : {}),
      pragma: "no-cache",
      "Cache-Control": "no-cache",
    };
  }

  protected globalErrorHandler: TGlobalErrorHandler = () => {};

  registerGlobalErrorHandler = once((handler: TGlobalErrorHandler) => {
    this.globalErrorHandler = handler;
  });

  fetchWithRetry<T>(request: () => Promise<T>, retries = 5): Promise<T> {
    return new Promise((resolve, reject) => {
      const attempt = (remainingRetries: number) => {
        request()
        .then((response) => {
          resolve(response);
        })
        .catch((err: BaseHttpError) => {
          if (err.name === "AbortError") {
            return reject(err);
          }

          const newClientError = new ClientError(err);

          if (newClientError.status === 401 && this.hasAccessToken()) {
            if (remainingRetries > 0) {
              Promise.race([
                this.tryToUpdateAccessToken(),
                new Promise((_, rejectTimeout) => setTimeout(() => rejectTimeout(new Error('Request timeout error')), 5000))
              ])
              .then(() => attempt(remainingRetries - 1))
              .catch((error) => reject(error));
            } else {
              reject(new Error(`Failed after ${5} retries`));
            }
          } else {
            reject(newClientError);
          }
        });
      };

      attempt(retries);
    });
  };

  doRequest<T>(segmentUrl: string, options: IOptions = {}): Promise<T> {
    return this.fetchWithRetry<T>(() => doRequest.call(this, segmentUrl, options), 5)
  }
}

export default Client;
